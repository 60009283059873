import React from "react"
import Layout from "../../components/Layout"
import useTeamData from "../../static_queries/useTeamData"
import LargeCard from "../../components/LargeCard"
import Tabs from "antd/lib/tabs"
import "antd/lib/tabs/style/index.css"
import BlogList from "../../components/BlogList"
import ContentHeader from "../../components/ContentHeader"
const { TabPane } = Tabs
export default function Team() {
  const { frontmatter } = useTeamData()
  return (
    <Layout b2c={true} page="home" showCta={false} title={frontmatter.title}>
      <Tabs type="card">
        <TabPane tab="Our Team" key="1">
          <LargeCard cards={frontmatter.card}/>

          <div>
            <div>
              <br/>
              <br/>
              <ContentHeader title={"Questions? Please Contact Us Using the Form Below."}/>
              <div className="container">
                <form name="contact-us"
                      data-netlify-honeypot="bot-field"
                      method="POST"
                      action="/success"
                      data-netlify="true">
                  <input type="hidden" name="form-name" value="contact-us"/>
                  <input type="hidden" name="bot-field"/>

                  <div className="hero-form newsletter-form field field-grouped">
                    <div className="control control-expanded">
                      <input required="true" className="input" type="email" name="email" placeholder="Email"/>
                    </div>
                    <div className="control">
                      <input required="true" className="input" type="tel" name="phone number"
                             placeholder="Phone Number"/>
                    </div>


                  </div>
                  <div className="field field-grouped mt-8">
                    <div className="control control-expanded">
                      <input required="true" className="input" type="text" name="your name" placeholder="Your Name"/>
                    </div>


                  </div>

                  <div className="control mt-8">
            <textarea required="true" className="textarea input" name="how find" rows="5"
                      placeholder="Your Question"/>
                  </div>
                  <p className="hero-paragraph mt-24">
                    <i>Your relationship, trust and time are important to us! Please rest assured that we will never
                      sell or
                      share your information. Thank you!
                    </i>
                  </p>
                  <p className="hero-cta mt-16">
                    <button type="submit" className="button button-primary button-shadow">Send Message</button>
                  </p>
                </form>
              </div>
            </div>
          </div>


        </TabPane>
        <TabPane tab="Recent News" key="2">
          <BlogList/>
        </TabPane>
      </Tabs>


    </Layout>
  )
}
