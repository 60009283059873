import { graphql, useStaticQuery } from "gatsby"

export default function useHomeData() {
  const data = useStaticQuery(graphql`
    query getTeamData {
      markdownRemark(frontmatter: {url: {eq: "team"}}) {
    id
    frontmatter {
      title
      card {
        content
        email
        image
        phone_number
        subtitle
        title
        thumbnail
      }
    }
  }
  }

  `)
  return data.markdownRemark
}
